export default class douyin_print {
  constructor(params) {
    this.doConnect(params)
  }

  socket = null

  doConnect(params) {
    try {
      this.socket = new WebSocket('ws://localhost:13888');
      //如果是https的话，端口是13529
      //socket = new WebSocket('wss://localhost:13999');
      // 打开Socket
      // 监听消息
      this.socket.onmessage = params.onmessage
      this.socket.onerror = params.onerror

      // 监听Socket的关闭
      this.socket.onclose = params.onclose
      
      this.socket.onopen =  (event) => {
        /**
         * 请求打印机列表demo
         * */
        this.socket.send(JSON.stringify(this.getRequestObject("getPrinters")));
      };
    } catch (error) {
      console.log(error)
    }
  }

  /***
   * 
   * 获取请求的UUID，指定长度和进制,如 
   * getUUID(8, 2)   //"01001010" 8 character (base=2)
   * getUUID(8, 10) // "47473046" 8 character ID (base=10)
   * getUUID(8, 16) // "098F4D35"。 8 character ID (base=16)
   *   
   */
  getUUID(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      let r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  }
  /***
   * 构造request对象
   */
  getRequestObject(cmd) {
    let request = new Object();
    request.requestID = this.getUUID(8, 16);
    request.version = "1.0";
    request.cmd = cmd;
    return request;
  }

  // 打印和预览
  // params 参数： printer 打印机, waybillArray 订单数组 , preview = false //true预览 false打印
  doPrint(params) {
    let request = this.getRequestObject("print");
    request.task = new Object();
    request.task.taskID = this.getUUID(8, 10);
    request.task.printer = params.printer;
    request.task.preview = params.preview || false;
    request.task.documents = params.waybillArray;
    this.socket.send(JSON.stringify(request));
    return request.task.taskID
  }

  // 获取打印任务状态
  getTaskStatus(Task){
    let request = this.getRequestObject("getTaskStatus");
    request.taskID = Task
    this.socket.send(JSON.stringify(request));
  }

}